import logo from '../img/logo/logo.png';
import config from '../config';

export default () => {
  return (

    <footer className="footer2">
        <div className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-5 col-xs-12">
                        <div className="footer-content logo-footer">
                            <div className="footer-head">
                                <div className="footer-logo">
                                  <a className="footer-white-logo" href="#"><img src={logo} alt=""/></a>
                                </div>
                                <p>
                                    If you are interested in seeing how the latest advances in Natural Language Understanding Technology can transform your business, then just enter your email and click "Subscribe".
                                </p>
                                <form action='/subscribe' method='post'>
                                <div className="subs-feilds">
                                    <div className="suscribe-input">
                                        <input type="email" className="email form-control width-80" id="sus_email" placeholder="Type Email"/>
                                        <button type="submit" id="sus_submit" className="add-btn" href='/subscribe'>Subscribe</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-3 col-xs-12">
                        <div className="footer-content">
                            <div className="footer-head">
                                <h4>Services Link</h4>
                                <ul className="footer-list">
                                    <li><a href="/customers/corporate">Corporates</a></li>
                                    <li><a href="/customers/government">Government</a></li>
                                    <li><a href="/customers/academia">Academia</a></li>
                                    <li><a href="/customers/resellers">Resellers and Partners</a></li>
                                </ul>
                                <ul className="footer-list hidden-sm">
                                    <li><a href="/support">Online support</a></li>
                                    <li><a href="/services/development">Development</a></li>
                                    <li><a href="/pricing">Pricing</a></li>
                                    <li><a href="/press">Press and Marketing</a></li>
              </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-12">
                        <div className="footer-content last-content">
                            <div className="footer-head">
                                <h4>Information</h4>
                                <div className="footer-contacts">
                <p><span>Tel :</span> {config.main_telephone}</p>
                <p><span>Email :</span> {config.main_email}</p>
                <p><span>Location :</span> {config.main_address}</p>
              </div>
                                <div className="footer-icons">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fa fa-github"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fa fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-area-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="copyright">
                            <p>
                                Copyright © 2022 &nbsp;
                                <a href="#">Comp Solutions Ltd.</a> All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

  )
}
