
import Header from './Header';
import Intro from './Intro';
import WelcomeService from './WelcomeService';
import Brand from './Brand';
import Services from './Services';
import Footer from './Footer';
import Page from './Page';
/*
import '../style.css';
import '../css/animate.css';
import '../css/venobox.css';
import '../css/meanmenu.min.css';
import '../css/magnific.min.css';
import '../css/owl.carousel.css';
import '../css/owl.transitions.css';
*/
export default function Site() {
  const home = (
    <div className='bg-color'>
    <Intro />
    <WelcomeService />
    <Brand />
    <Services />
    </div>
  )

  return (
    <Page contents={home} />
  )

}
