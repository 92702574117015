import logo from './logo.svg';
import Site from './components/Site';
import ComingSoon from './components/ComingSoon';
import './style.css';
import {Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' exact element={<Site />} />
        <Route path='/subscribe' element={<ComingSoon title="Subscribing to our Email List" />}/>
        <Route path='/contact' element={<ComingSoon title="Contacting Us"/>} />
        <Route path='/about' element={<ComingSoon title="Comp Solutions"/>} />
        <Route path='/services' element={<ComingSoon title="Our Services"/>} />
        <Route path='/services/harvesting' element={<ComingSoon title="Corpus Harvesting"/>} />
        <Route path='/services/hosting' element={<ComingSoon title="LLM Hosting"/>} />
        <Route path='/services/development' element={<ComingSoon title="HLP Development"/>} />
        <Route path='/services/apis' element={<ComingSoon title="API Reference"/>} />
        <Route path='/services/big-data' element={<ComingSoon title="Big Data Services"/>} />
        <Route path='/research/deep-learning' element={<ComingSoon title="Deep Learning Research"/>} />
        <Route path='/research/grammars' element={<ComingSoon title="Language and Grammar Model Reserach"/>} />
        <Route path='/research/corpora' element={<ComingSoon title="Our Corpora"/>} />
        <Route path='/research/comprehension' element={<ComingSoon title="Language Comprehension Research"/>} />
        <Route path='/customers/corporate' element={<ComingSoon title="Corporate Customers"/>} />
        <Route path='/customers/government' element={<ComingSoon title="Government Customers"/>} />
        <Route path='/customers/academia' element={<ComingSoon title="Academic Customers and Partners"/>} />
        <Route path='/customers/resellers' element={<ComingSoon title="Resellers and Partners"/>} />
        <Route path='/support' element={<ComingSoon title="Support"/>} />
        <Route path='/pricing' element={<ComingSoon title="Pricing"/>} />
        <Route path='/press' element={<ComingSoon title="Public Relations and Announcements"/>} />
        <Route path='/robots/faqs' element={<ComingSoon title="Chatbox"/>} />
      </Routes>
    </div>
  );
}

export default App;
