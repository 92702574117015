import logo from '../img/logo/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/font-awesome.min.css';
import config from '../config';

export default () => {
  //<Button variant='outline-success'>Hello</Button>}
    const ss = {
      backgroundColor: 'black',
      width: '100%'
    }
    return (
      <div style={ss}>
      <Container>
      <Row><Col>
      <div className='topbar-left'>
      <ul>
          <li><a href="#"><i className="fa fa-envelope"></i> {config.main_email}</a></li>
          <li><a href="#"><i className="fa fa-phone-square"></i> {config.main_telephone}</a></li>
      </ul>
      </div>
      </Col></Row>
      <Row><Col>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/"><img src={logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href='/'>Home</Nav.Link>
              <Nav.Link href='/#about'>About</Nav.Link>
              <Nav.Link href="/#services">Services</Nav.Link>
            </Nav>
            <Button variant="outline-primary" href='/contact'>Contact Us</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </Col></Row>
      </Container>
      </div>
  );
}
