import Page from './Page';
import {Row, Col, Container} from 'react-bootstrap';

export default ( {title} ) => {
  const content = (
    <div className="welcome-area bg-color area-padding-2">
    <Container>
    <Row>
    <Col>
    <h2>{title}: Coming Soon!</h2>
    <p>Please be patient, this page about {title} is being written and will appear here soon!</p>
    </Col>
    </Row>
    </Container>
    </div>
  )
  return <Page contents={content} />
}
