import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/flaticon.css';

export default () => {
  return (
    <div className="welcome-area bg-color area-padding-2">
        <Container>
            <Row>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="well-services">
                            <div className="well-img">
                                <a className="big-icon" href="/research/deep-learning"><i className="flaticon-006-chip"></i></a>
                            </div>
                            <div className="main-wel">
                                <div className="wel-content">
                                    <h4>Deep Learning Models</h4>
                                    <p>We build and fine-tune state of the art language models using Transformers and Recurrent Neural Networks as the basis of our technology.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="well-services">
                            <div className="well-img">
                                <a className="big-icon" href="/research/grammars"><i className="flaticon-109-report"></i></a>
                            </div>
                            <div className="main-wel">
                                <div className="wel-content">
                                    <h4>Language and Grammar Modules</h4>
                                    <p>We build hand-crafted grammars which work with our deep-learning models to provide a full range of language processing products.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="well-services">
                            <div className="well-img">
                                <a className="big-icon" href="/research/corpora"><i className="flaticon-130-web"></i></a>
                            </div>
                            <div className="main-wel">
                                <div className="wel-content">
                                    <h4>Corpus and Dataset Collection</h4>
                                    <p>We collect and process billions of words of the world's communications every month from multiple online and offline sources.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="well-services">
                            <div className="services-img">
                                <a className="big-icon" href="/research/comprehension"><i className="flaticon-095-networking"></i></a>
                            </div>
                            <div className="main-wel">
                                <div className="wel-content">
                                    <h4>Language Comprehension</h4>
                                    <p>We build models that actually understand what people are writing and saying, and can use these models to answer their questions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

            </Row>
        </Container>
    </div>
  )
}
