
import bgimg from '../img/background/bg1.jpg';


export default () => {
  const style1 = {
    visibility: 'visible',
    animationDelay: '0.3s',
    animationName: 'fadeInUp'
  };
  const style2 = {
    visibility: 'visible',
    animationDelay: '0.5s',
    animationName: 'fadeInUp'
  };
  const style3 = {
    visibility: 'visible',
    animationDelay: '0.3s',
    animationName: 'fadeInUp'
  };


  return (

    <div className="intro-area intro-area-2">
        <div className="bg-wrapper">
          <img src={bgimg} alt=""/>
        </div>
    <div className="intro-content">
      <div className="slider-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="layer-1 wow fadeInUp">
                <h2 className="title2">Transforming <span className="color">Human Language Processing</span> </h2>
              </div>
              <div className="layer-2 wow fadeInUp">
                <p>Next generation processing of human languages for intelligent applications.</p>
              </div>
              <div className="layer-3 wow fadeInUp" >
                <a href="/robots/faqs" className="ready-btn">Talk with Chatbox, our friendly AI chatbot...</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
