import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import w1 from '../img/service/w1.png';
import w2 from '../img/service/w2.png';
import w3 from '../img/service/w3.png';
import w4 from '../img/service/w4.png';
import w5 from '../img/service/w5.png';



export default () => {

  return (
    <div className="service-area bg-color area-padding" id="services">
      <Container>
         <Row>
        <div className="col-md-4 col-sm-6 col-xs-12">
                      <div className="single-service text-service">
                          <h3><span className="color">Services &amp; Products</span></h3>
                          <p>We research, design, tune and host systems which rely on cutting-edge Large Language Models (LLMs) for cutting-edge game-changing products which can communicate more like us humans than ever before.</p>
                          <a className="service-btn" href="/services">Learn more</a>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                      <div className="single-service">
                          <div className="service-img">
                              <img src={w1} alt=""/>
                          </div>
                          <div className="service-content">
                              <h4><a href="services/harvesting">Corpus Harvesting</a></h4>
                              <p>Our suite of advanced harvesting tools enables us to find and build and clean the multi-billion word training corpora that our models rely on.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                      <div className="single-service text-service">
                          <div className="service-img">
                              <img src={w2} alt=""/>
                          </div>
                          <div className="service-content">
                              <h4><a href="/services/apis">Simple APIs</a></h4>
                              <p>We build and publish APIs which enable you to use our pre-trained models to build your language processing tools.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                      <div className="single-service">
                          <div className="service-img">
                              <img src={w3} alt=""/>
                          </div>
                          <div className="service-content">
                              <h4><a href="/services/development">New Product Development</a></h4>
                             <p>Want to have the latest cutting edge HLP technology but don't have the AI development resource? We've got you covered with our trained HLP technology specialists.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                      <div className="single-service text-service">
                          <div className="service-img">
                              <img src={w4} alt=""/>
                          </div>
                          <div className="service-content">
                              <h4><a href="/services/hosting">GPU LLM Hosting Services</a></h4>
                              <p>Do you have a model that you need to scale to millions of interactions per hour?  Our hosting services all over the world allow you to deliver your application at scale and cost-effectively.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                      <div className="single-service">
                          <div className="service-img">
                              <img src={w5} alt=""/>
                          </div>
                          <div className="service-content">
                              <h4><a href="/services/big-data">Big Data Service</a></h4>
                              <p>We provides a range of services that allow you to query and access the huge datasets that you host with us, or that we harvest for you.</p>
                          </div>
                      </div>
                  </div>
          </Row>
      </Container>
  </div>
  );
}
