import Header from './Header';
import Footer from './Footer';

import '../style.css';
import '../css/animate.css';
import '../css/venobox.css';
import '../css/meanmenu.min.css';
import '../css/magnific.min.css';
import '../css/owl.carousel.css';
import '../css/owl.transitions.css';

export default ( {contents} ) => {
  return (
    <div>
      <Header />
      <div>
      {contents}
      </div>
      <Footer />
    </div>
  )
}
