import about from '../img/about/ab.png';

export default () => {
  return (
    <div className="about-area area-padding" id='about'>
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="about-content">
                        <img src={about} alt=""/>
                    </div>
                </div>
               <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="about-text">
                        <h3>About <span className="color">Comp</span>.</h3>
      <p> Set up in 2022 by the founders of Daxtra Technologies, Compsolutions is a for-profit research and development company which aims to bring recently developed exciting new technologies to the spacce of document and data analysis, and deliver components that can be deployed to production environments.</p>
        <p>Our goals include:</p>
        <ul className="hidden-sx">
                            <li>To make recent advances in HLP technology accessible for every software developer to incorporate into their products.</li>
                            <li>To build state of the art information extraction, matching and management technologies that learn from user behaviour.</li>
                            <li>To research the science of new network and models to help us understand how to improve the models even further.</li>
                            <li>To advance the understanding of how human beings come to learn and use language as we do.</li>
                        </ul>
                        <a className="ab-btn" href="/about">Learn more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
